<template>
    <div class="site-logo"><img :src="src" :alt="alt" /></div>
</template>

<script>
    export default {
        name: "siteLogo",
        props: {
            alt:String,
            src: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>