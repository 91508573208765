<template>
    <router-link to="/">
        <transition name="opacity" mode="out-in" transitionend>
            <site-logo v-if="logo"
                       :class="{'small':!isHome}"
                       :src="logo" :alt="siteName"></site-logo>
        </transition>
    </router-link>
</template>

<script>
    import store from "../store";
    import SiteLogo from "../components/siteLogo";

    export default {
        name: "Logo",
        components: {SiteLogo},
        props: {isHome: Boolean},
        data() {
            return {
                isPortrait: window.innerWidth < window.innerHeight
            }
        },
        computed: {
            logo() {
                return store.logo
            },

            siteName() {
                return store.name
            }
        },

        created() {
            window.addEventListener('resize', this.checkOrientation);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.checkOrientation);
        },

        methods: {
            checkOrientation() {
                this.isPortrait = window.innerWidth < window.innerHeight
            }
        }
    }
</script>

<style scoped>

</style>